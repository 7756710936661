import React, { useRef, useEffect } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Fix Leaflet's default icon path issues
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const calculateBoundingBox = (properties) => {
  if (!properties || properties.length === 0) return null;

  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  properties.forEach(property => {
    const lat = parseFloat(property.Address.Latitude);
    const lng = parseFloat(property.Address.Longitude);

    if (!isNaN(lat) && !isNaN(lng)) {
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    }
  });

  if (minLat === Infinity || maxLat === -Infinity || minLng === Infinity || maxLng === -Infinity) {
    return null;
  }

  return [
    [minLat, minLng],
    [maxLat, maxLng]
  ];
};

const MapUpdater = ({ properties, boundingBox, onAudioTourClick }) => {
  const map = useMap();

  useEffect(() => {
    if (boundingBox) {
      map.fitBounds(boundingBox, { padding: [50, 50] });
    }
  }, [boundingBox, map]);

  return (
    <>
      {properties.map(property => {
        const position = [parseFloat(property.Address.Latitude), parseFloat(property.Address.Longitude)];
        if (isValidCoordinate(position)) {
          return (
            <Marker key={property.Asset.Id} position={position}>
              <Popup className="custom-popup">
                <div className="flex flex-col w-64">
                  <img 
                    className="w-full h-40 object-cover rounded-t"
                    src={property.Media[0]?.Url || 'https://via.placeholder.com/300x200.png?text=No+Image'} 
                    alt={property.Address.AddressLine1}
                  />
                  <div className="p-2 space-y-1">
                    <h3 className="font-bold text-base leading-tight">{property.Address.AddressLine1}</h3>
                    <p className="text-xs text-gray-600 leading-tight">
                      {property.Address.City}, {property.Address.StateOrProvince}
                    </p>
                    <p className="text-xs leading-tight">
                      {property.Asset.BedroomsTotal} beds • {property.Asset.BathroomsFull} baths • {property.Asset.LotSizeSquareFeet} sqft
                    </p>
                    <p className="text-green-600 font-bold text-base leading-tight">
                      {property.Asset.FormatedPrice}
                    </p>
                    <button
                      onClick={() => onAudioTourClick(property.Asset.AudioTourUrls.BrandedShowcasePage)}
                      className="w-full bg-blue-500 hover:bg-blue-600 text-white text-xs font-bold py-1.5 px-4 rounded mt-1 transition duration-300"
                    >
                      View Audio Tour
                    </button>
                  </div>
                </div>
              </Popup>
            </Marker>
          );
        }
        return null;
      })}
    </>
  );
};

const isValidCoordinate = (coord) => {
  return Array.isArray(coord) && 
         coord.length === 2 && 
         !isNaN(coord[0]) && 
         !isNaN(coord[1]) && 
         coord[0] !== 0 && 
         coord[1] !== 0;
};

const PropertyMap = ({ properties, onAudioTourClick }) => {
  const mapRef = useRef(null);
  const boundingBox = calculateBoundingBox(properties);

  const defaultCenter = [39.8283, -98.5795]; // Default to center of US
  const defaultZoom = 4; // Default zoom

  return (
    <MapContainer 
      center={defaultCenter}
      zoom={defaultZoom}
      style={{ height: '100%', width: '100%' }}
      ref={mapRef}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapUpdater properties={properties} boundingBox={boundingBox} onAudioTourClick={onAudioTourClick} />
    </MapContainer>
  );
};

export default PropertyMap;