import React, { useEffect, useRef } from 'react';

const AudioTour = ({ url, onClose }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'stopAllAudio') {
        const iframe = iframeRef.current;
        if (iframe) {
          iframe.contentWindow.postMessage('stopAllAudio', '*');
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className="audio-tour-modal">
      <div className="audio-tour-wrapper">
        <div className="audio-tour-container">
          <iframe 
            ref={iframeRef}
            src={url} 
            title="Audio Tour" 
            className="audio-tour-iframe"
          />
        </div>
        <button className="audio-tour-close-btn" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default AudioTour;