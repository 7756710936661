import React from 'react';
import PropertySearch from './components/PropertySearch';
import './index.css'; // This now includes Tailwind CSS

const App = () => {
  return (
    <div className="App">
      <PropertySearch />
    </div>
  );
};

export default App;