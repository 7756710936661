import React from 'react';
import { FaBed, FaBath, FaRulerCombined } from 'react-icons/fa';

const PropertyCard = ({ property, onAudioTourClick }) => {
  const imageUrl = property.Media && property.Media.length > 0
    ? property.Media[0].Url
    : 'https://via.placeholder.com/300x200?text=No+Image';

  const avatarUrl = property.Agent.Avatar;

  // Format square footage
  const formattedSqFt = property.Asset.LotSizeSquareFeet 
    ? `${property.Asset.LotSizeSquareFeet} SqFt`
    : 'N/A';

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <img 
        src={imageUrl}
        alt={property.Address.AddressLine1 || 'Property'} 
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-1">{property.Address.AddressLine1 || 'Untitled Property'}</h2>
        <p className="text-gray-600 text-sm leading-tight mb-2">
          {property.Address.City}, {property.Address.StateOrProvince} {property.Address.PostalCode}
        </p>
        <div className="flex justify-between items-center mb-2">
          <p className="text-lg font-bold">{property.Asset.FormatedPrice || 'Price N/A'}</p>
          <div className="flex space-x-3 text-sm text-gray-600">
            <span className="flex items-center">
              <FaBed className="mr-1" /> {property.Asset.BedroomsTotal || 'N/A'}
            </span>
            <span className="flex items-center">
              <FaBath className="mr-1" /> {property.Asset.BathroomsFull || 'N/A'}
            </span>
            <span className="flex items-center">
              <FaRulerCombined className="mr-1" /> {formattedSqFt}
            </span>
          </div>
        </div>
        <div className="border-t pt-4 mt-2">
          <div className="flex items-start mb-4">
            {avatarUrl && (
              <div className="w-16 h-16 mr-3 flex-shrink-0 overflow-hidden rounded-full">
                <img 
                  src={avatarUrl}
                  alt={property.Agent.FullName || 'Agent'} 
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            <div className="flex flex-col flex-grow">
              <span className="font-semibold">{property.Agent.FullName || 'Unknown Agent'}</span>
              <span className="text-sm text-gray-600">{property.Agent.BrokerageName || 'Unknown Company'}</span>
              <span className="text-sm text-gray-600">{property.Agent.PhoneNumber || 'No phone number'}</span>
            </div>
          </div>
          <button
            onClick={() => onAudioTourClick(property.Asset.AudioTourUrls.BrandedShowcasePage)}
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300"
          >
            View Audio Tour
          </button>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;