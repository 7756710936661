import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import PropertyCard from './PropertyCard';
import AudioTourModal from './AudioTourModal';
import PropertyMap from './PropertyMap';
import NavHeader from './NavHeader';
import SearchBar from './SearchBar';

const PropertySearch = () => {
  const [properties, setProperties] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [audioTourUrl, setAudioTourUrl] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('US');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [mapCenter, setMapCenter] = useState([39.8283, -98.5795]); // Center of the US
  const [mapZoom, setMapZoom] = useState(4);
  const [blacklist] = useState(['example@domain.com', 'mailinator.com', 'directoffer.com', 'encora.com','rkpr@outlook.com','newbeta@gmail.com','mizbasadia123@gmail.com','maulikencora@gmail.com','doaudiotours.com']);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const fetchProperties = useCallback(async () => {
    try {
      //console.log('Fetching properties with params:', { city, state, page }); // Log parameters without country
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-audio-tours`, {
        params: {
          city,
          state, // Ensure this is "KY" when "Kentucky" is selected
          paginate: 10,
          page,
          Key: process.env.REACT_APP_API_TOKEN,
        },
      });
      
      if (response.data.success) {
        //console.log('API response data:', response.data); // Log the response data
        const filteredProperties = response.data.data.data.filter(property => {
          const agentEmail = property.Agent.Email.toLowerCase();
          const agentDomain = agentEmail.split('@')[1];
          return !blacklist.some(item => 
            agentEmail.includes(item.toLowerCase()) || agentDomain === item.toLowerCase()
          );
        });

        setProperties(filteredProperties);
        setTotalPages(response.data.data.last_page);
        
        // Calculate the center of all properties
        const validCoordinates = filteredProperties
          .map(property => ({
            lat: parseFloat(property.Address.Latitude),
            lng: parseFloat(property.Address.Longitude)
          }))
          .filter(coord => !isNaN(coord.lat) && !isNaN(coord.lng) && coord.lat !== 0 && coord.lng !== 0);

        if (validCoordinates.length > 0) {
          const sumLat = validCoordinates.reduce((sum, coord) => sum + coord.lat, 0);
          const sumLng = validCoordinates.reduce((sum, coord) => sum + coord.lng, 0);
          const centerLat = sumLat / validCoordinates.length;
          const centerLng = sumLng / validCoordinates.length;

          setMapCenter([centerLat, centerLng]);
          setMapZoom(6); // Adjust this value to get the desired zoom level
        } else {
          // If no valid coordinates, set to default (center of US)
          setMapCenter([39.8283, -98.5795]);
          setMapZoom(4);
        }
      } else {
        //console.error('API request failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  }, [city, state, page, blacklist]);

  useEffect(() => {
    //console.log('Current state code:', state); // Log the state code
    fetchProperties();
  }, [fetchProperties, state]);

  const handleSearch = () => {
    setPage(1);
    fetchProperties();
    setIsSearchExpanded(false); // Close the search bar when search is triggered
  };

  const handleAudioTourClick = (url) => {
    if (window.innerWidth < 1024) { // Assuming 1024px as the breakpoint for mobile
      window.open(url, '_blank');
    } else {
      setAudioTourUrl(url);
      setIsModalVisible(true);
    }
  };

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);
    setAudioTourUrl('');
  }, []);

  // Remove or comment out this unused function
  // const toggleSearchExpansion = () => {
  //   setIsSearchExpanded(!isSearchExpanded);
  // };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="flex flex-col h-screen">
      <NavHeader />
      <SearchBar 
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        country={country}
        setCountry={setCountry}
        onSearch={handleSearch}
        isExpanded={isSearchExpanded}
        setIsExpanded={setIsSearchExpanded}
      />
      <div className="flex-1 flex flex-col lg:flex-row overflow-hidden">
        <div className="w-full lg:w-2/5 xl:w-1/3 flex flex-col overflow-hidden bg-white shadow-lg">
          <div className="flex-1 overflow-y-auto">
            {properties.length > 0 ? (
              <div className="space-y-4 p-4">
                {properties.map(property => (
                  <PropertyCard 
                    key={property.Asset.Id} 
                    property={property} 
                    onAudioTourClick={handleAudioTourClick}
                  />
                ))}
              </div>
            ) : (
              <div className="text-center py-8 text-gray-500">No properties found</div>
            )}
          </div>
          {totalPages > 1 && (
            <div className="mt-4 flex justify-center items-center pb-4">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
                className="px-3 py-1 bg-blue-500 text-white rounded-l disabled:bg-gray-300"
              >
                <FaChevronLeft />
              </button>
              <span className="px-4 py-1 bg-gray-200">
                Page {page} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
                className="px-3 py-1 bg-blue-500 text-white rounded-r disabled:bg-gray-300"
              >
                <FaChevronRight />
              </button>
            </div>
          )}
        </div>
        <div className="hidden lg:block lg:w-3/5 xl:w-2/3">
          <PropertyMap 
            properties={properties} 
            onAudioTourClick={handleAudioTourClick}
            center={mapCenter}
            zoom={mapZoom}
          />
        </div>
      </div>
      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" style={{ zIndex: 1000 }}>
          <div className="relative w-11/12 h-5/6 max-w-5xl">
            <button 
              onClick={handleModalClose}
              className="absolute -top-10 right-0 text-white hover:text-gray-300 focus:outline-none"
            >
              <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <div className="w-full h-full bg-white rounded-lg overflow-hidden">
              <AudioTourModal 
                url={audioTourUrl} 
                onClose={handleModalClose}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertySearch;