import React from 'react';
import { FaSearch, FaChevronDown, FaChevronUp, FaUndo } from 'react-icons/fa';
import './SearchBar.css'; // We'll keep using the existing CSS

const states = [
    { name: 'Alabama', code: 'AL' },
    { name: 'Alaska', code: 'AK' },
    { name: 'Arizona', code: 'AZ' },
    { name: 'Arkansas', code: 'AR' },
    { name: 'California', code: 'CA' },
    { name: 'Colorado', code: 'CO' },
    { name: 'Connecticut', code: 'CT' },
    { name: 'Delaware', code: 'DE' },
    { name: 'Florida', code: 'FL' },
    { name: 'Georgia', code: 'GA' },
    { name: 'Hawaii', code: 'HI' },
    { name: 'Idaho', code: 'ID' },
    { name: 'Illinois', code: 'IL' },
    { name: 'Indiana', code: 'IN' },
    { name: 'Iowa', code: 'IA' },
    { name: 'Kansas', code: 'KS' },
    { name: 'Kentucky', code: 'KY' },
    { name: 'Louisiana', code: 'LA' },
    { name: 'Maine', code: 'ME' },
    { name: 'Maryland', code: 'MD' },
    { name: 'Massachusetts', code: 'MA' },
    { name: 'Michigan', code: 'MI' },
    { name: 'Minnesota', code: 'MN' },
    { name: 'Mississippi', code: 'MS' },
    { name: 'Missouri', code: 'MO' },
    { name: 'Montana', code: 'MT' },
    { name: 'Nebraska', code: 'NE' },
    { name: 'Nevada', code: 'NV' },
    { name: 'New Hampshire', code: 'NH' },
    { name: 'New Jersey', code: 'NJ' },
    { name: 'New Mexico', code: 'NM' },
    { name: 'New York', code: 'NY' },
    { name: 'North Carolina', code: 'NC' },
    { name: 'North Dakota', code: 'ND' },
    { name: 'Ohio', code: 'OH' },
    { name: 'Oklahoma', code: 'OK' },
    { name: 'Oregon', code: 'OR' },
    { name: 'Pennsylvania', code: 'PA' },
    { name: 'Rhode Island', code: 'RI' },
    { name: 'South Carolina', code: 'SC' },
    { name: 'South Dakota', code: 'SD' },
    { name: 'Tennessee', code: 'TN' },
    { name: 'Texas', code: 'TX' },
    { name: 'Utah', code: 'UT' },
    { name: 'Vermont', code: 'VT' },
    { name: 'Virginia', code: 'VA' },
    { name: 'Washington', code: 'WA' },
    { name: 'West Virginia', code: 'WV' },
    { name: 'Wisconsin', code: 'WI' },
    { name: 'Wyoming', code: 'WY' }
];

const SearchBar = ({ city, setCity, state, setState, country, setCountry, onSearch }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);  // Default to expanded

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch();
    setIsExpanded(false); // Close the search bar when search is clicked
  };

  const handleReset = () => {
    setCity('');
    setState('');
    setCountry('US');
  };

  return (
    <div className="search-bar-container">
      <div className="search-bar-header">
        <h2>Search</h2>
        <button onClick={toggleExpand} className="expand-button">
          {isExpanded ? (
            <>Hide Filters <FaChevronUp /></>
          ) : (
            <>Show Filters <FaChevronDown /></>
          )}
        </button>
      </div>
      {isExpanded && (
        <form onSubmit={handleSearch} className="search-form">
          <input
            type="text"
            placeholder="Enter city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="search-input"
          />
          <select
            value={state}
            onChange={(e) => setState(e.target.value)}
            className="search-select"
          >
            <option value="">Select a state</option>
            {states.map((state) => (
              <option key={state.code} value={state.code}>
                {state.name}
              </option>
            ))}
          </select>
          <select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="search-select"
          >
            <option value="US">United States</option>
            {/* Add more country options if needed */}
          </select>
          <div className="search-button-group">
            <button type="submit" className="search-button">
              <FaSearch /> Search
            </button>
            <button type="button" className="reset-button" onClick={handleReset}>
              <FaUndo /> Reset
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SearchBar;