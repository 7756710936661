import React from 'react';

const NavHeader = ({ logoWidth = 150, logoHeight = 'auto' }) => {
  return (
    <header className="flex justify-between items-center px-4 bg-[#000e54] h-16">
      <div className="flex items-center">
        <a href="https://doaudiotours.com">
          <img 
            src="/images/doaudiotours-logo-white.png" 
            alt="Do Audio Tours Logo" 
            className="h-10 w-auto"
            style={{ width: logoWidth, height: logoHeight }}
          />
        </a>
      </div>
      <div>
        <a
          href="https://app.doaudiotours.com/create-account"
          className="px-4 py-2 text-white border border-white rounded-full hover:bg-white hover:text-[#000e54] transition-colors duration-300"
        >
          Agent Access
        </a>
      </div>
    </header>
  );
};

export default NavHeader;